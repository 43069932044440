<template>
  <div class="pdf-wrap">
    <div class="canvas-container">
      <canvas ref="myCanvas" class="pdf-container"></canvas>
    </div>
    <div class="pagination-wrapper">
      <button type="text" @click="clickPre">{{ $t('language.pre') }}</button>
      <span>{{ $t('language.no') }}&nbsp;{{ pageNo }} / {{ pdfPageNumber }}&nbsp;{{ $t('language.page') }}</span>
      <button type="text" @click="clickNext">{{ $t('language.next') }}</button>
    </div>
  </div>
</template>

<script>
import User from '@/api/user'

const pdfJS = require('pdfjs-dist')
pdfJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry')
export default {
  name: 'UserAgreement',
  components: {},
  props: {},
  data() {
    return {
      pageNo: null,
      pdfPageNumber: null,
      pdfTotalPages: 1,
      renderingPage: false,
      pdfData: null, // PDF的base64
      scale: 1.5, // 缩放值
      pdfUrl: ''
    }
  },
  computed: {
    // pdfUrl: function () {
    //   return this.$route.params.lang === 'zh_CN' ? '/zh-CN.pdf' : '/en.pdf'
    // }
  },
  methods: {
    // 加载pdf数据
    loadPdfData() {
      this.pdfData = pdfJS.getDocument(this.pdfUrl)
      this.renderPage(1)
      this.renderScrollPdf()
    },
    // 渲染PDF
    renderPage(num) {
      this.renderingPage = true
      this.pdfData.promise.then((pdf) => {
        this.pdfPageNumber = pdf.numPages
        pdf.getPage(num).then((page) => {
          // 获取DOM中为预览PDF准备好的canvasDOM对象
          let canvas = this.$refs.myCanvas
          let viewport = page.getViewport(this.scale)
          canvas.height = viewport.height
          canvas.width = viewport.width
          let ctx = canvas.getContext('2d')
          let renderContext = {canvasContext: ctx, viewport: viewport}
          page.render(renderContext).then(() => {
            this.renderingPage = false
            this.pageNo = num
          })
        })
      })
    },
    // 上一页
    clickPre() {
      if (!this.renderingPage && this.pageNo && this.pageNo > 1) {
        this.renderPage(this.pageNo - 1)
        document.getElementsByClassName('pdf-wrap')[0].scrollTo(0, 0)
      }
    },
    // 下一页
    clickNext() {
      if (
          !this.renderingPage &&
          this.pdfPageNumber &&
          this.pageNo &&
          this.pageNo < this.pdfPageNumber
      ) {
        this.renderPage(this.pageNo + 1)
        document.getElementsByClassName('pdf-wrap')[0].scrollTo(0, 0)
      }
    },
    renderScrollPdf() {
      this.pdfData.promise.then((pdf) => {
        this.pdfTotalPages = pdf.numPages
      })
    },
  },
  mounted() {
    this.$i18n.locale = this.$route.params.lang === 'zh_CN' ? 'zh_CN' : 'en';
    localStorage.setItem('locale', this.$route.params.lang === 'zh_CN' ? 'zh_CN' : 'en');
    User.getPdf(this, e => {
      if (e.code != 0) return
      const res = e.data.find(v => v.lang === this.$route.params.lang)?.url
      this.pdfUrl = 'https://demo-admin.theinfinitywallet.com/agreementViewer/' + (res || e.data.find(v => v.lang === 'en')?.url)
      this.loadPdfData()
    })
  },
}
</script>

<style>
.pdf-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.canvas-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
}

.pdf-container {
  width: 110%;
  height: 100%;
  margin: 0 -5%;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #ffffff;
}

.pagination-wrapper button {
  width: 30%;
  height: 40px;
  border-radius: 10px;
  border: 0;
  background: #cccccc;
  color: #ffffff;
  box-shadow: none;
  font-size: 14px;
  margin: 0 10px;
  font-weight: bold;
}

.pagination-wrapper button:hover {
  opacity: .8;
}

.pagination-wrapper span {
  color: #666666;
  font-size: 14px;
}
</style>
